import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="text-center py-5">
    <h1>Strony nie znaleziono</h1>
    <p>Sprawdź wpisany adres lub skorzystaj z nawigacji.</p>
    </div>
  </Layout>
)

export default NotFoundPage
